<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      {{ backendData }}
    </p>
    <button @click="fetchData">Obtener Datos</button>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      backendData: 'Cargando...' // Muestra un mensaje mientras se espera la respuesta
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch('https://ucpsback.sytes.net/hello'); // Cambia 'api-endpoint' por la ruta de tu API
        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
        const data = await response.json();
        this.backendData = data.mensaje; // Asegúrate de que coincida con el formato de la respuesta
      } catch (error) {
        console.error('Error:', error);
        this.backendData = 'Error al obtener los datos';
      }
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
